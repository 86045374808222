<template>
  <section class="m-better-product m-scroll" ref="scroll"  @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="Best Sellers" >
      <router-link to="/en/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
        <m-dot :number="cartNum" :animate="dotAnimate" ref="dot"></m-dot>
      </router-link>
    </m-header>

    <!-- 滚动切换 -->
    <van-tabs :offset-top="tabTop" title-active-color="#ED2A24" title-inactive-color="#000000" v-model="activeSectionId"
    style="position:sticky;top:0px;z-index:99;margin-bottom:10px">
      <van-tab :title="item.title_en" v-for="(item, index) in classifyList" :key="index" :name="item.id"></van-tab>
    </van-tabs>
    <goods-columns @cartClick="handleCart" :data="goodsList" :columns="classifyList[activeIndex].row_pro_num"></goods-columns>
    <p class="search-no" v-if='noMore'>No More Products</p>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MHeader      from '@/components/en/m-header.vue'
import MDot         from '@/components/en/m-dot.vue'
import MSku         from '@/components/en/m-sku.vue'
import GoodsColumns from '@/components/en/goods-columns.vue'
import MAnimate     from '@/components/en/cart-animate.vue'
import CartFlex     from '@/components/en/cart-flex.vue'

import { getTypeList, getTypeGoodsList } from '@/api/en/gift.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  mixins: [ MixinScroll ],
  name:'Index',
  components:{ MHeader,  MDot, GoodsColumns, MSku, MAnimate, CartFlex },
  data(){
    return {
      offsetTop      : 0,
      tab            : 0,          // tabs选中的面板
      recomnd        : 4,
      loading        : false,
      skuModal       : false,
      tabTop         : 44,
      dotAnimate     : false,
      start          : false,
      start_dom      : null,
      cartNum        : 0,
      scrollTop      : false,
      activeSectionId: '',
      page           : 1,
      noMore         : false,
      islock         : false,
      classifyList   : [{}],
      activeIndex    : 0,
      goodsList      : [],
      goodsId        : ''
    }
  },

  mounted(){
    let clientWidth = document.body.clientWidth
    this.tabTop = 44/375*clientWidth
  },

  methods:{
    // 内容滑动事件
    handleScroll(e){
      this.offsetTop = e.target.scrollTop

      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getBetterGoodsListHandle()
        }
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 点击月份筛选
    handleMonth(month){
      this.month = month
      let timer = setTimeout( () => {
        this.filter = false
        clearTimeout(timer)
      }, 300)
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start      = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getBetterListHandle() {
      getTypeList(this.$route.query.id).then(res => {
        if(res) {
          this.classifyList = res.data.filter(item => {
            return item.status == 1
          })
          // if(res.data.length > 0) {
          //   this.activeSectionId = this.classifyList[0].id
          // }
        }
      })
    },
    getBetterGoodsListHandle() {
      this.islock = true
      getTypeGoodsList({page: this.page, type_id: this.activeSectionId, cid: this.$route.query.id}).then(res => {
          if(res) {
              this.goodsList = this.goodsList.concat(res.data.data)
          } else {
              this.noMore = true
          }
      }).finally(() => {
          this.loading = false
          this.islock  = false
      })
    }
  },
  created() {
    this.getBetterListHandle()
  },
  watch: {
    activeSectionId(val) {
      if(val) {
        this.classifyList.forEach((el, index) => {
          if(el.id == val) {
            this.activeIndex = index
          }
        })
        this.goodsList = []
        this.page      = 1
        this.islock    = false
        this.noMore    = false
        this.getBetterGoodsListHandle()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.right-img {
  width:20px;
  height:20px;
  margin:6px;
}
.search-no{line-height:80px;text-align:center;color:#888;font-size:14px}
@import './list.less';
</style>